// REMOVE CLASS NO-JS BODY
document.querySelector('body').classList.remove('no-js');

// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

    $('.js-replace-img').each(function() {

        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
}

jQuery(function() {
    if ($(".js-replace-img")) {
        replaceSrc();
    }
});


(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 72) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// ANCRE
jQuery ( document ).ready ( function($) {

var hash= window.location.hash;

if ( hash == '' || hash == '#' || hash == undefined ) return false;

      var target = $(hash);

      headerHeight = 200;

      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

      if (target.length) {
        $('html,body').stop().animate({
          scrollTop: target.offset().top - 100 //offsets for fixed header
        }, 'linear');

      }

} );

// SCROLL TO ANCHOR PRODUCT
jQuery(".link-ancre-product").click(function(e) {
    var aid = $(this).attr("href");
    //$('html,body').animate({scrollTop: $(aid).offset().top - 80},'slow');
    $('html,body').animate({
    scrollTop: $(aid).offset().top - 120 //offsets for fixed header
    }, 'linear');
});

// BTN FILTRES
jQuery('#btnMobileFiltre').click(function(e) {
  e.preventDefault();
  jQuery('.aside-product').toggleClass('open');
  jQuery('#btnMobileFiltre').toggleClass('cross');
  jQuery('body').toggleClass('overflow');
});

// ADD CLASS TO FILTER PARENT
jQuery ( document ).ready ( function($) {
if (jQuery('.children li').hasClass('sf-option-active')) {
    jQuery(this).parent().addClass('active');
}
} );
